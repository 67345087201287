@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

.default-button {
  border-radius: 8px;
  border: none;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 18px;

  @include mobile() {
    font-size: 12px;
  }
}

.primary {
  background-color: #2581c4;
  color: #ffffff;
}

.secondary {
  border-color: #2581c4;
  color: #2581c4;
  background-color: #ffffff;
}
