@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

.section2 {
  padding: 3rem 8rem;
  justify-content: center;

  @include mobile() {
    padding: 1rem;
  }

  @include tablet() {
    padding: 1rem 2rem;
  }

  &__icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  &__icon {
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    @include mobile() {
      flex-basis: 100%;
    }

    @include tablet() {
      flex-basis: 50%;
      justify-content: flex-start;
    }
  }
}
