@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

.section1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eeeff0;
  padding: 3rem 3rem;

  @include mobile() {
    padding: 1rem;
  }

  @include tablet() {
    padding: 1rem 2rem;
  }
}
