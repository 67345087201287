body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  /* margin: 0 8rem; */
}

.text-sm {
  font-size: 24px;
}

.text-md {
  font-size: 32px;
}

.text-lg {
  font-size: 57px;
}

.font-weight-bold {
  font-weight: bold;
}

@media screen and (max-width: 425px) {
  .text-lg {
    font-size: 42px;
  }

  .text-md {
    font-size: 24px;
  }

  .text-sm {
    font-size: 18px;
  }
}
