@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large_phone() {
  @media screen and (min-width: 425px) and (max-width: 600px) {
    @content;
  }
}

@mixin mini_phone(){
  @media screen and (max-width: 292px) {
    @content;
  }
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;

  .navbar__link-text{
    cursor: pointer;
  }

  &__sunai-logo {
    img {
      background-size: cover;
      background-position: center;
    }
  }
  

  @include mobile() {
    padding: 1rem 1rem;
    flex-wrap: nowrap;
  }

  @include tablet() {
    padding: 1rem 2rem;
    flex-wrap:nowrap;
    overflow: hidden;
  }

  &__btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__btns-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mobile() {
      display: none;
    }

    @include large_phone(){
      display: none;
    }
  }

  &__link-text {
    font-size: 1rem;
    color: #1a5c8b;
    line-height: 140%;
    margin: 0 1rem;
    
    @include tablet(){
      margin: 0 0.5rem;
    }
  }

  &__contact-btn {
    margin-left: 1rem;
    @include tablet(){
      margin: 0 0.5rem;
    }
  }
}
