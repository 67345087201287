@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

.section4 {
  padding: 3rem 8rem;
  background-color: #2275b2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile() {
    padding: 1rem;
  }

  &__text {
    color: #ffffff;
    text-align: center;
  }
}
