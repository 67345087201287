.remaining-texts{
    // margin-left:60vw;
    // margin-right: 5vw;

    // margin-top:-170vh;

    position: relative;
    .two-locations{
        display: flex;
        flex-direction: row;
        .two-locations-1{
            padding-right: 80px;
            .two-locations-1-1{
                font-size: 1.3rem;
                font-weight:bold;
                margin-top: 30px;
                margin-bottom:15px;
            }
            .two-locations-1-2{
                margin-bottom: 08px;
            }
            .two-locations-1-3{
                a:visited{
                    color:black;
                }

                a:hover{
                    color:blue;
                    text-decoration: none; 
                }
                a{
                    color:black
                }
            }
            
           
        }
        .two-locations-2{
            
            .two-locations-2-1{
                margin-top: 30px;
                font-size:1.3rem;
                font-weight:bold;
                margin-bottom: 15px;
            }
            .two-locations-2-2{
                margin-bottom: 08px;
                a:visited{
                    color:black;
                  }
                  a:hover{
                    color:blue;
                    text-decoration: none;

                  }
            }
            .two-locations-2-3{
                font-weight: 400;
                a:visited{
                    color:black
                }
                a:hover{
                    color:blue;
                    text-decoration: none;

                  }
                a{
                    color:black;
                }
            }

           
        }
    }
}

@media screen and (min-width: 425px) and (max-width: 1024px){
    .remaining-texts{
        position: relative;
        // margin-top: 1rem;
        // margin-left: 1.875rem;
        // margin-right: 25px;
        
    }
}

@media screen and (max-width: 425px){
    .remaining-texts{
        position: relative;
        // margin-top: 1rem;
        // margin-left: 25px;
        // margin-right: 25px;
        .two-locations{
            display:flex;
            flex-direction: column;
        }
        
    }


}