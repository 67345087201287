@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

.section3 {
  padding: 3rem 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile() {
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
  }

  @include tablet() {
    padding: 1rem 2rem;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column-reverse;
  }

  &__image {
    flex-basis: 50%;

    @include mobile() {
      flex-basis: 100%;
      margin: 2rem 0;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    @include tablet() {
      flex-basis: 25%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__text {
    flex-basis: 50%;

    @include tablet() {
      flex-basis: 20%;
    }
  }
}
