.whole-forms{
    // margin-left: 110px;
    // margin-top:50px;
    //width:556px;
    padding: 1rem 3rem;   

    .relative-rem-text {
        padding: 15rem 3rem 15rem 6rem;
    }


}

.form_label{
    //width:456px;
    padding:5px;
}

.forms__text-1{
    margin-bottom: 20px;
}

.forms__text-2{
    margin-bottom: 20px;
}



@media screen and (max-width: 425px) {
      
      

      .whole-forms{
        padding: 1rem 1rem;
        //margin-left: 35px;
        //width: 356px;
        .form_label{
            //width:286px;
            padding:5px;
        }

        .relative-rem-text {
            padding: 2rem 0;
        }
    
      }
      
        
      
}

  

@media screen and (min-width: 425px) and (max-width: 992px) {
      
      
     
    .whole-forms{
        //margin-left:50px;
        //margin-top:60px;
        //width:456px;
        padding: 2rem 2rem;
        .form_label{
            //width:386px;
            padding:5px;
        }
        .btn-text{
            margin-bottom: 100px;
        }

        .relative-rem-text {
            padding: 2rem 0;
        }
    }
}

.success-message {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: lightgreen;
    justify-content: center;
    color: green;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    opacity: 1;
}
.failure-message {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: red;
    justify-content: center;
    color: white;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
    opacity: 1;
}

