@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
    .small-texts{
      font-size: 12px;
    }
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large_phone() {
  @media screen and (min-width: 425px) and (max-width: 600px) {
    @content;
  }
}

.footer {
  padding: 3rem 3rem;
  background-color: #EEEFF0;
  // margin-top: 1rem;
  
  .footer__link-section:nth-child(1){
    cursor: pointer;
  }

  .small-texts{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.875rem;
    .small-texts-1{
      display: flex;
      

      div:nth-child(1) {
        padding-right: 1.5rem;
      }

      @include mobile() {
        flex-direction: column;
        div{
          margin-bottom: 1rem;
        }
      }
      
    }

    @include mobile() {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    
  }

  @include mobile() {
    padding: 1rem 1rem;
  }

  @include tablet() {
    padding: 1rem 2rem;
  }

  &__section1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    @include mobile(){
      flex-wrap: wrap;
    }

    @include large_phone(){
      flex-wrap: wrap;
    }
  }

  &__texts {
    flex-basis: 60%;

    @include mobile() {
      flex-basis: 100%;
      margin-bottom: 1.25rem;
    }

    @include tablet() {
      flex-basis: 80%;
    }
  }

  &__image {
    flex-basis: 40%;
    .social-media-icons{
      width:200px;
      display:flex;
      margin-top:50px;
      justify-content: space-around;
      @include mobile{
        display:flex;
        flex-basis:100%
      }
    }

    @include mobile() {
      flex-basis: 100%;
      margin-bottom: 3rem;
    }
  }

  &__links {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-left: 50px;

    @include mobile() {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
    }

    @include tablet() {
      justify-content: space-between;
    }

    @include large_phone(){
      margin-left: 0;
    }
  }
  
  &__link-section {
    display: flex;
    flex-direction: column;
    .footer__link__contact:visited{
      color: black;
    }
    
  
    @include mobile() {
      margin-top: 1rem;
    }

    @include tablet() {
      margin-bottom: 1rem;
    }
  }

  &__link {
    flex-basis: 33.33%;
    line-height: 150%;
    font-size: 24px;
    line-height: 150%;
    color: #2e3339;
    
    

    @include mobile() {
      font-size: 14px;
    }
  }

  &__privacy {
    line-height: 150%;
    font-size: 24px;
    line-height: 150%;
    color: #2e3339;
    text-align: center;
    margin-top: 1rem;

    @include mobile() {
      font-size: 14px;
    }
  }

  &__copyright {
    font-size: 16px;
    line-height: 140%;
    color: #535c68;
    text-align: center;
    margin-top: 1rem;
    white-space: nowrap
  }

  // &__copyright::before {
  //  content: "\00a9"; }
}
