@mixin mobile() {
  @media screen and (max-width: 425px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 425px) and (max-width: 1024px) {
    @content;
  }
}

.section-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin-bottom: 50px;
  .section-hero__texts{
    font-weight: 600;
  }

  @include mobile() {
    padding: 1rem;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  @include tablet() {
    padding: 1rem 2rem;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  &__section1 {
    flex-basis: 50%;

    @include tablet() {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__texts {
    @include tablet() {
      text-align: center;
    }
  }

  &__section2 {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;

    @include mobile() {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    @include tablet() {
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__button {
    @include mobile() {
      font-size: 24px;
      width: 100%;
    }
  }
}
